<template>
  <el-dialog
    title="Details"
    :visible.sync="dialogShowDetailFormVisible"
    width="80rem"
    :close-on-click-modal="true"
    ><el-divider>details info</el-divider>
    <el-form :model="form" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Molecular properties"
            :label-width="formLabelWidth"
            prop="molecular_properties"
          >
            <el-input
              v-model="form.molecular_properties"
              placeholder="Enter a Property or Activity"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Electron cloud/field data accuracy"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="form.type">
              <el-radio label="TRAINED_NETWORKS_WITH_DATA"
                >trained networks with data</el-radio
              >
              <el-radio label="DATA_SETS">data sets</el-radio>
            </el-radio-group>
          </el-form-item></el-col
        ></el-row
      >
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Total number of molecules"
            :label-width="formLabelWidth"
            prop="total_number_of_molecules"
          >
            <el-input
              v-model="form.total_number_of_molecules"
              placeholder="Enter the total number of molecules"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="Download link"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-input
              v-model="form.download_address"
              placeholder="Enter the download address"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Status"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-tag
              :type="
                form.status == 1
                  ? 'info'
                  : form.status == 2
                  ? 'success'
                  : form.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                form.status == 1
                  ? "ToBeReviewed"
                  : form.status == 2
                  ? "Passed"
                  : form.status == 3
                  ? "Unpassed"
                  : "Offline"
              }}</el-tag
            ></el-form-item
          ></el-col
        ></el-row
      >
      <el-row type="flex">
        <el-col>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Detailed description</span>
            </div>
            <div v-html="form.details_content"></div> </el-card
        ></el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose()" icon="el-icon-circle-close"
        >Close</el-button
      >
    </div></el-dialog
  >
</template>
    
    <script>
export default {
  name: "NetworkModelShowDetail",
  data() {
    return {
      formLabelWidth: "15rem",
      dialogShowDetailFormVisible: false,
      form: "",
    };
  },
  methods: {
    handleOpen(data) {
      this.dialogShowDetailFormVisible = true;
      this.form = data;
    },
    handleClose() {
      this.dialogShowDetailFormVisible = false;
      this.$emit("getInfo");
    },
  },
};
</script>
    
    <style>
</style>