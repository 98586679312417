<template>
  <el-card class="info_card">
    <div slot="header">
      <el-row type="flex" justify="space-between"
        ><el-col :span="10"><span>Search for trained networks/data sets</span></el-col>
        <el-col :span="10">
          <el-input
            placeholder="Enter a Property or Activity"
            v-model="keyWord"
            class="input-with-select"
            @keyup.enter.native="getInfo"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getInfo"
            ></el-button>
          </el-input> </el-col
      ></el-row>
    </div>
    <div>
      <el-table :data="infoList" border style="width: 100%" v-loading="loading">
        <el-table-column prop="molecular_properties" label="Properties targeted">
        </el-table-column>
        <el-table-column prop="type" label="Type">
        </el-table-column>
        <el-table-column prop="status" width="115" label="Status"
          ><template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status == 1
                  ? 'info'
                  : scope.row.status == 2
                  ? 'success'
                  : scope.row.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                scope.row.status == 1
                  ? "ToBeReviewed"
                  : scope.row.status == 2
                  ? "Passed"
                  : scope.row.status == 3
                  ? "Unpassed"
                  : "Offline"
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Operatiion" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-view"
              @click="handleShow(scope.$index, scope.row)"
              >View</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <NetworkModelShowDetail ref="NetworkModelShowDetail" />
  </el-card>
</template>
<script>
import NetworkModelShowDetail from "../NetworkModelShowDetail/index.vue";
export default {
  name: "NetworkModelSelect",
  components: { NetworkModelShowDetail },
  data() {
    return {
      loading: true,
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
      form: {},
      isLoading: false,
      //模糊搜索关键词
      keyWord: "",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    handleShow(index, data) {
      this.$refs.NetworkModelShowDetail.handleOpen(data);
    },
    getInfo() {
      this.$store
        .dispatch("networkModel/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
          keyWord: this.keyWord,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
  },
};
</script>
    
    <style>
</style>